import axios from "axios";
import validator from "validator";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { frFR, enUS } from "@mui/x-data-grid";
import { registerLocale } from "react-datepicker";
import { fr, it, enGB, pt, de, nl, es } from "date-fns/locale/";
import * as constants from "../constants";

/**
 *
 * @returns identification token
 */
export function getToken() {
  return localStorage.getItem("token");
}

/**
 * Remove identification token
 */
export function removeToken() {
  console.log("remove token");
  localStorage.clear();
}

export function isEmailValid(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

/**
 * Call read data API
 * @param {String} url API URL to read data
 * @param {Array} options API options, like authentication
 * @returns API status code
 */
export const fetchData = async (url, options) => {
  try {
    const response = await axios(url, options, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (error) {
    const errorData = { ...error };
    throw errorData;
  }
};

/**
 * Call post data API
 * @param {String} API URL to read data
 * @param {Returned data} data
 * @param {String} authToken token authentication
 * @returns API status code
 */

export async function postData(url, data, authToken) {
  const response = await axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response.data;
}
/*
export async function postData(url, data, authToken) {
  try {
    const response = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(getMessage(error));
  }
}
*/
/**
 * Convert a String date to Date object
 * @param {String} dt
 * @returns Date
 */
export function stringToDate(dt) {
  const parts = dt.split(getDateFormatSeparator());
  return new Date(parts[2], parts[1], parts[0]);
}

/**
 * Call update data API
 * @param {String} url API url to call
 * @param {String} id object id to update
 * @param {String} data data to update
 * @param {String} authToken token authentication
 * @returns API status code
 */
export async function updateData(url, id, data, authToken) {
  try {
    const response = await axios.put(`${url}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(getMessage(error));
  }
}

/**
 *
 * @param {String} url API url to call
 * @param {String} id object id to delete
 * @param {String} authToken token authentication
 * @returns API status code
 */
export async function deleteData(url, id, authToken) {
  try {
    const response = await axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * Convert all numeric attributes of an object to String, then return modified object
 * @param {Object} obj Object ti update
 * @returns updated object
 */
export function convertNumericAttributesToString(obj) {
  const convertedObj = {};

  for (const key in obj) {
    if (typeof obj[key] === "number") {
      convertedObj[key] = obj[key].toString();
    } else {
      convertedObj[key] = obj[key];
    }
  }

  return convertedObj;
}

/**
 * Initialize all attributes object with an empty string
 * @param {Object} obj
 * @returns
 */
export function initializeObjectWithEmptyStrings(obj) {
  const initializedObj = {};

  for (const key in obj) {
    initializedObj[key] = "";
  }

  return initializedObj;
}

/**
 * Check an email address validity
 * @param {String} email Email address to check
 * @returns true if address is valid, false otherwise
 */
export function isValidEmail(email) {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  return emailRegex.test(email);
}

/**
 * Check a date validity
 * @param {Date} date date to check
 * @returns true if date is valid, false otherwise
 */
export function isValidDate(date) {
  return validator.isDate(date, { format: "DD/MM/YYYY" });
}
/**
 * Return a formatted date for given parameter (use with Datagrid)
 * @param {object} params
 * @returns
 */
export function formatDate(params) {
  if (params && params.value) {
    if (params.value.length > 10) {
      params.value = params.value.substring(0, 10);
    }
    const date = params.value ? new Date(params.value) : null;
    if (date) {
      return date.toLocaleDateString();
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export function formatDateString(dt) {
  const date = dt ? new Date(dt) : null;
  if (date) {
    return date.toLocaleDateString();
  } else {
    return "";
  }
}

/**
 * Return a formatted date for given parameter (use with Datagrid)
 * @param {object} params
 * @returns
 */
export function getLocaleCountryName(translations, params) {
  if (params.value) {
    return translations["country_" + params.value.toLowerCase()];
  } else {
    return "";
  }
}
export function getPropertyStatus(translations, params) {
  if (params.value) {
    return translations["property_status_" + params.value.toLowerCase()];
  } else {
    return "";
  }
}

export function translate(translations, tag) {
  return translations[tag];
}

/**
 *
 * @returns date format sepeartor according to browser locale
 */
export const getDateFormatSeparator = () => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  const dateFormatter = new Intl.DateTimeFormat(undefined, options);
  const dateFormat = dateFormatter.formatToParts(new Date());

  //find date separator
  for (const part of dateFormat) {
    if (part.type === "separator" && part.value !== " ") {
      return part.value;
    }
  }

  // return default value if nothing was found
  return "/";
};

/**
 * return error messge from a given error
 * @param {Error} error
 */
export function getMessage(error) {
  let msg = "";
  if (typeof error === "string") {
    console.log("1");
    msg = error;
  } else if (typeof error.response !== "undefined") {
    console.log("2");
    if (typeof error.response.data !== "undefined") {
      console.log("3");
      if (typeof error.response.data.message !== "undefined") {
        console.log("4");
        if (typeof error.response.data.message.exception !== "undefined") {
          console.log("5");
          if (error.response.data.exception.length > 0) {
            console.log("6");
            msg = error.response.data.exception[0].message;
          }
        }
        if (typeof error.response.data.message === "string") {
          console.log("7");
          msg = error.response.data.message;
        }
      }
      if (typeof error.response.data === "string") {
        console.log("8");
        msg = error.response.data;
      }
    } else if (typeof error.message === "string") {
      console.log("9");
      msg = error.message;
    }
  }
  console.log("msg : " + msg);
  return msg;
}

/**
 *
 * @returns Datagrid material ui translations
 */
export function getGridTexts() {
  return localStorage.getItem("userLanguage") === "fr-FR" ? frFR : enUS;
}

export async function fetchTranslations(userLanguage) {
  try {
    console.log("fetch translations for " + userLanguage);
    const data = await fetchData(
      `${constants.API_URL}translations/${userLanguage}`,
      null
    );
    return data;
  } catch (error) {
    throw error;
  }

  /*
    const response = await fetch(
      `${constants.API_URL}translations/${userLanguage}`
    );
    if (!response.ok) {
      console.log("response : ");
      console.log(response.message);
      //throw new Error("Failed to fetch translations");
    }
    const translationsData = await response.json();
    return translationsData;
  } catch (error) {
    console.error("Catch Error fetching translations:");
    throw error;
  }
  */
}

/**
 * Fetches data from an API endpoint, processes it with optional mapping, and sets it using a provided setter function.
 *
 * @param {string} url - The URL of the API endpoint to fetch data from.
 * @param {object} requestOptions - The request options to configure the API request.
 * @param {function} setterFunction - Optional. The function to set the fetched data.
 * @param {boolean} mapping - Optional. If true, the data will be mapped to a new object.
 */
export async function fetchDataFromAPI(
  url,
  requestOptions,
  setterFunction,
  mapping
) {
  try {
    const data = await fetchData(url, requestOptions);
    const newData = mapping ? data.map((item) => ({ ...item })) : data;
    if (setterFunction !== null) {
      setterFunction(newData);
    }
    return newData;
  } catch (error) {
    throw error;
  }
}

export const useErrorHandler = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const handleAPIError = (error) => {
    console.log("handleAPIError called");
    setErrorMessage(getMessage(error));
  };
  return { errorMessage, handleAPIError };
};

export const getUserLang = () => {
  return localStorage.getItem("userLanguage") || "en-GB";
};

/**
 *
 * @param {*} password
 * @returns true if password is strong
 */
export function isStrongPassword(password) {
  //minimun size is 10 characters
  if (password.length < 10) {
    return false;
  }

  // At list 1 uppercase, 1 lowercase, 1 number, 1 special character
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/;
  return regex.test(password);
}

export function isValidZipCode(countryCode, postalCode) {
  const postalCodeRegex = getPostalCodeRegex(countryCode);
  if (!postalCodeRegex) {
    // Unknown country
    return true;
  }

  return postalCodeRegex.test(postalCode);
}

function getPostalCodeRegex(countryCode, postalCode) {
  const postalCodeRegexMap = {
    AL: /^\d{4}$/i, // Exemple : 1001
    AD: /^AD\d{3}$/i, // Exemple : AD100
    AT: /^\d{4}$/i, // Exemple : 1010
    BE: /^\d{4}$/i, // Exemple : 1000
    BA: /^\d{5}$/i, // Exemple : 71000
    BG: /^\d{4}$/i, // Exemple : 1000
    HR: /^\d{5}$/i, // Exemple : 10000
    CY: /^\d{4}$/i, // Exemple : 1234
    CZ: /^\d{5}$/i, // Exemple : 12000
    DK: /^\d{4}$/i, // Exemple : 1000
    EE: /^\d{5}$/i, // Exemple : 10120
    FI: /^\d{5}$/i, // Exemple : 00100
    FR: /^\d{5}$/i, // Exemple : 75001
    DE: /^\d{5}$/i, // Exemple : 10115
    GR: /^\d{5}$/i, // Exemple : 151 24
    HU: /^\d{4}$/i, // Exemple : 1052
    IS: /^\d{3}$/i, // Exemple : 101
    IE: /^D\d{2}$/i, // Exemple : D02
    IT: /^\d{5}$/i, // Exemple : 00144
    LV: /^LV-\d{4}$/i, // Exemple : LV-1000
    LI: /^(FL-)?\d{4}$/i, // Exemple : 9490
    LT: /^LT-\d{5}$/i, // Exemple : LT-01108
    LU: /^\d{4}$/i, // Exemple : 1234
    MK: /^\d{4}$/i, // Exemple : 1000
    MT: /^[A-Z]{3} \d{4}$/i, // Exemple : VLT 2000
    MD: /^\d{4}$/i, // Exemple : 2012
    MC: /^980\d{2}$/i, // Exemple : 98000
    ME: /^\d{5}$/i, // Exemple : 81000
    NL: /^\d{4} [A-Z]{2}$/i, // Exemple : 1000 AB
    NO: /^\d{4}$/i, // Exemple : 0010
    PL: /^\d{2}-\d{3}$/i, // Exemple : 00-001
    PT: /^\d{7}$/i, // Exemple : 1000-001
    RO: /^\d{6}$/i, // Exemple : 050711
    RU: /^\d{6}$/i, // Exemple : 101000
    SM: /^4789\d$/i, // Exemple : 47890
    RS: /^\d{6}$/i, // Exemple : 11000
    SK: /^\d{5}$/i, // Exemple : 811 01
    SI: /^\d{4}$/i, // Exemple : 1000
    ES: /^\d{5}$/i, // Exemple : 28001
    SE: /^\d{5}$/i, // Exemple : 111 22
    CH: /^\d{4}$/i, // Exemple : 8001
    TR: /^\d{5}$/i, // Exemple : 06100
    UA: /^\d{5}$/i, // Exemple : 01001
    GB: /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/i, // Exemple : SW1A 1AA
  };
  return postalCodeRegexMap[countryCode];
}

/**
 *
 * @param {String} str String
 * @returns given string with all non letters + space + "-" removed
 */

export function removeNonLettersCharacter(str, allowNumbers = false) {
  let regexPattern = allowNumbers
    ? /[^a-zA-Z àáâãäçèéêëìíîïòóôõöùúûüÿ\-0-9']/g
    : /[^a-zA-Z àáâãäçèéêëìíîïòóôõöùúûüÿ\-']/g;
  return str.replace(regexPattern, "");
}
/**
 * Configures the date picker library to support multiple languages.
 * This function registers the necessary locales for the date picker.
 *
 * @returns {void}
 */
export function configureDatePicker() {
  registerLocale("fr", fr);
  registerLocale("it", it);
  registerLocale("en", enGB);
  registerLocale("pt", pt);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("nl", nl);
}
