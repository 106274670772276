import React, { useEffect, useState, useContext } from "react";
import { TranslationContext } from "../../Components/TranslationContext";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import * as constants from "../../utils/constants";
import { getToken, fetchDataFromAPI, useErrorHandler } from "../../utils/hooks";
import Message from "../../Components/Message";
import ErrorMessage from "../../Components/ErrorMessage";
import {
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
} from "@mui/material";

function Renew() {
  const { errorMessage, handleAPIError } = useErrorHandler();
  const { translations } = useContext(TranslationContext);
  const API_URL_CREATE_ORDER = `${constants.API_URL}subscription/paidrenew/createOrder`;
  const API_URL_CAPTURE_ORDER = `${constants.API_URL}subscription/paidrenew/captureOrder`;
  const API_URL_PAYEMENT_OPTIONS = `${constants.API_URL}subscription/paidrenew/payementOptions`;
  const auth = `Bearer ${getToken()}`;
  const [message, setMessage] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("SUB12");
  const [availableSubscriptions, setAvailableSubscriptions] = useState("");
  const [payementConfiguration, setPayementConfiguration] = useState([]);
  const requestOptions = {
    headers: {
      Authorization: auth,
    },
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchApiData = async () => {
      try {
        const response = await fetchDataFromAPI(
          API_URL_PAYEMENT_OPTIONS,
          requestOptions,
          null,
          false
        );
        setPayementConfiguration(response.paypalConfiguration);
        let subs = [];
        response.availableSubscriptions.forEach((s) => {
          const [k, d, v] = s.split(":");
          subs.push({ key: k, duration: d, price: v });
        });
        setAvailableSubscriptions(subs);
      } catch (error) {
        handleAPIError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchApiData(); // read data
  }, []);

  function onSubscriptionChanged(subscription) {
    setSelectedSubscription(subscription.target.value);
  }

  return (
    <>
      <h1>{translations.subscription_renew_action}</h1>
      <ErrorMessage message={errorMessage} />
      <Message message={message} />
      <div className="mainPageContainer">
        {loading ? (
          <p>{translations.loading}</p>
        ) : (
          <>
            <form>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={selectedSubscription}
                  row // Utilisez row pour afficher les boutons sur la même ligne
                >
                  {availableSubscriptions.map((sub) => (
                    <FormControlLabel
                      value={sub.key}
                      key={sub.key}
                      control={<Radio />}
                      onChange={onSubscriptionChanged}
                      label={
                        <span>
                          {sub.duration} {translations.month.toLowerCase()} :{" "}
                          {sub.duration * sub.price} € ({sub.price} € / mois)
                        </span>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <br></br>
              <br></br>
              <PayPalScriptProvider options={payementConfiguration}>
                <PayPalButtons
                  forceReRender={[selectedSubscription]}
                  style={{
                    shape: "rect",
                    layout: "vertical",
                    color: "silver",
                    label: "paypal",
                  }}
                  createOrder={async () => {
                    try {
                      const response = await fetch(API_URL_CREATE_ORDER, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: auth,
                        },
                        // use the "body" param to optionally pass additional order information
                        // like product ids and quantities
                        body: JSON.stringify({
                          product: selectedSubscription,
                        }),
                      });
                      const orderData = await response.json();
                      if (orderData.id) {
                        return orderData.id;
                      } else {
                        const errorDetail = orderData?.details?.[0];
                        const errorMessage = errorDetail
                          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                          : JSON.stringify(orderData);

                        throw new Error(errorMessage);
                      }
                    } catch (error) {
                      console.error(error);
                      setMessage(
                        `Could not initiate PayPal Checkout...${error}`
                      );
                    }
                  }}
                  onApprove={async (data, actions) => {
                    try {
                      const response = await fetch(
                        API_URL_CAPTURE_ORDER + "/" + data.orderID,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: auth,
                          },
                          body: JSON.stringify({
                            product: selectedSubscription,
                          }),
                        }
                      );
                      const orderData = await response.json();
                      if (orderData.hasOwnProperty("message")) {
                        if (response.status === 200) {
                          setMessage(orderData.message);
                        } else {
                          handleAPIError(orderData.message);
                        }
                      } else {
                        // Three cases to handle:
                        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                        //   (2) Other non-recoverable errors -> Show a failure message
                        //   (3) Successful transaction -> Show confirmation or thank you message

                        const errorDetail = orderData?.details?.[0];

                        if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                          // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                          // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                          return actions.restart();
                        } else if (errorDetail) {
                          // (2) Other non-recoverable errors -> Show a failure message
                          throw new Error(
                            `${errorDetail.description} (${orderData.debug_id})`
                          );
                        } else {
                          // (3) Successful transaction -> Show confirmation or thank you message
                          // Or go to another URL:  actions.redirect('thank_you.html');
                          const transaction =
                            orderData.purchase_units[0].payments.captures[0];
                          handleAPIError(
                            `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                          );
                          console.log(
                            "Capture result",
                            orderData,
                            JSON.stringify(orderData, null, 2)
                          );
                        }
                      }
                    } catch (error) {
                      console.error(error);
                      handleAPIError(
                        `Sorry, your transaction could not be processed...${error}`
                      );
                    }
                  }}
                />
              </PayPalScriptProvider>
            </form>
          </>
        )}
      </div>
    </>
  );
}

export default Renew;
