import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const InactivityHandler = ({ children }) => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const logoutUser = () => {
    // Action à exécuter lors de l'inactivité (par ex. rediriger vers login)
    navigate("/Logout");
  };

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Réinitialise le timer
    }
    // Déclenche une déconnexion après 30 minutes (1800 000 ms)
    timeoutRef.current = setTimeout(logoutUser, 30 * 60 * 1000);
  };

  // Écouter tous les événements utilisateur pour réinitialiser le timer
  useEffect(() => {
    const events = ["mousemove", "mousedown", "keypress", "touchstart"];

    // Réinitialise le timer à chaque activité utilisateur
    const handleActivity = () => resetTimer();

    events.forEach((event) => window.addEventListener(event, handleActivity));

    resetTimer(); // Déclenche le timer à l'initialisation

    // Cleanup : supprimer les écouteurs d'événements lors du démontage
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Nettoyer le timer
      }
    };
  }, []);

  return <>{children}</>; // Rendu des enfants (composants de l'application)
};

export default InactivityHandler;
